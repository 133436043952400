.hasLinkAction {
  div, p, span, h3 {
    cursor: pointer;
  }
}

.card {
  background: linear-gradient(to right, #FD4A5B 0%, #AF1292 100%);
  border-radius: 5px;
  color: white;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  margin-bottom: 25px;
  transition: box-shadow .2s;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
  }

  .cardBody {
    padding: 25px;

    p {
      min-height: 40px;
    }

    h3 {
      max-height: 37px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .cardFooter {
    display: flex;
  }

  .cardFooterItem {
    border: thin solid rgba(0, 0, 0, 0.1);
    border-bottom: none;
    border-left: none;
    padding: 10px;
    flex-grow: 1;
    text-align: center;
  }


  .cardFooterItem:nth-child(3n) {
    background: linear-gradient(#d1d0d1, white);
    border-right: none;
    border-bottom-right-radius: 3px;
    color: #333;
  }

  .cardFooterItem a {
    color: inherit;
  }

  .cardBadge {
    background: white;
    color: #1a1a1a;
    position: absolute;
    font-size: 12px;
    padding: 3px 10px;
    border-radius: 2px;
    top: -10px;
    left: 25px;
    font-weight: bold;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  }
}

.helpButton {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: none;
  border-radius: 10px;
  color: #FFF;
  padding: 4px;
  margin: 0;
  line-height: 11px;

  i {
    border: #fff solid 1px;
    border-radius: 20px;
    padding: 5px;
    font-size: 11px;
  }
}