@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body {
    font-family: 'Poppins', sans-serif !important;
}

.lead {
    font-size: 30px !important;
}

.react-datepicker-wrapper {
    display: block !important;
}
.rbg-app .loading-icon {
    text-align: center;
}
.rbg-app .loading-icon i {
    font-size: 80px;
}
.pagination-holder .result-text {
    float: left;
    margin: 10px 0;
    line-height: 30px; }
.pagination-holder .pagination {
    float: right;
    margin: 10px 0; }


.fancyCardFooter {
    display:flex;
}

.unselectedFooter {
    padding: 10px;
    flex-grow: 1;
    text-align: center;
    background: linear-gradient(to right, #FD4A5B 0%, #AF1292 100%);
    color: white;
    font-weight: bold;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    cursor: pointer;
    text-transform: uppercase;
}

.cardImage {
    height: 150px;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    background-position: center center;
    background-size: 100%;
}

.btn.btn-primary {
    background: linear-gradient(to right, #FD4A5B 0%, #AF1292 100%);
    border: none;
    font-weight: bold;
    text-transform: uppercase;
}

.cardFooter {
    display: flex;
    border-top: thin solid rgba(0, 0, 0, 0.1);

}
.cardFooter span {
    width: 50%;
    padding: 5px 15px;
    border-right: thin solid rgba(0,0,0,0.1);
    align-items: center;
    text-align: center;
}
.cardFooter span:last-child{
    border-right: none;
}
.cardFooter span input {
    border: none;
    border-bottom: 2px solid rgba(0,0,0,0.1);
    width: 100%;
    display: block;
}

.emptySummary {
    padding: 25px;
    text-align: center;
    border: 3px dashed rgba(0,0,0,0.1);
    border-radius: 5px;

}

.alert {
    border-radius: 0 !important;
    text-align: center;
}

.cursor-pointer {
    cursor: pointer;
}

.footer {
    margin-top: 150px;
    font-size: 13px;
}

.tabContent {
    padding: 10px 15px;
    border-right: thin solid #dee2e6;
    border-left: thin solid #dee2e6;
    border-bottom: thin solid #dee2e6;
}

.nav-tabs {
    font-weight: bold;
}

th, td {
    font-size: 13px;
}

.bigger {
    font-size: 20px;
    font-weight: 300;
}
@media print {
    .jumbotron {
        display: none;
    }
    .hidden-print {
        display: none;
    }
}

.rbg-text {
    background: linear-gradient(to right, #FD4A5B 0%, #AF1292 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.quantity-selector--icon {
    border: 1px solid #ccc;
    border-radius: 50%;
    margin: 0 10px;
    text-align: center;
    padding: 6px;
    color: darkgray;
    cursor: pointer;
    background: white;
}

.quantity-selector--number {
    border: 1px solid #ccc;
    margin: 0 10px;
    font-size: 16px;
    text-align: center;
    padding: 5px 10px;
    font-weight: bold;
    background: white;
}

.quantity-selector--disabled {
    cursor: not-allowed;
    background-color: #f4f4f4;
    color: #AAAAAA;
}