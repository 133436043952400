.modal-body {
  .slot-grid {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .btn {
      width: 100%;
      text-align: center;
      margin: 5px;
    }
  }
}