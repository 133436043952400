button.overlay-trigger {
  padding: 0;
  margin: 0;
  position: relative;
  border: none;
  i {
    border-radius: 20px;
    border: #cecece solid 1px;
    color: blue;
    padding: 2px 4px;
    font-size: 11px;
    margin: 0 4px 0 4px;
    line-height: 9px;
  }
}