.mainNavbar {
  //background: white !important;
}

.navDropdown {
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 50px;
  top: 120%;
}

.dropItem {
  padding-left: 15px;
}

.invoicesDropdown .btn-primary, .invoicesDropdown.show .btn-primary {
  background: none !important;
  color: rgba(0, 0, 0, .5);
  text-transform: none;
  font-weight: normal;
  border: none;
  line-height: 27px;
}