@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
html,
body {
    font-family: 'Poppins', sans-serif !important;
}

.lead {
    font-size: 30px !important;
}

.react-datepicker-wrapper {
    display: block !important;
}
.rbg-app .loading-icon {
    text-align: center;
}
.rbg-app .loading-icon i {
    font-size: 80px;
}
.pagination-holder .result-text {
    float: left;
    margin: 10px 0;
    line-height: 30px; }
.pagination-holder .pagination {
    float: right;
    margin: 10px 0; }


.fancyCardFooter {
    display:flex;
}

.unselectedFooter {
    padding: 10px;
    flex-grow: 1;
    text-align: center;
    background: linear-gradient(to right, #FD4A5B 0%, #AF1292 100%);
    color: white;
    font-weight: bold;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    cursor: pointer;
    text-transform: uppercase;
}

.cardImage {
    height: 150px;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    background-position: center center;
    background-size: 100%;
}

.btn.btn-primary {
    background: linear-gradient(to right, #FD4A5B 0%, #AF1292 100%);
    border: none;
    font-weight: bold;
    text-transform: uppercase;
}

.cardFooter {
    display: flex;
    border-top: thin solid rgba(0, 0, 0, 0.1);

}
.cardFooter span {
    width: 50%;
    padding: 5px 15px;
    border-right: thin solid rgba(0,0,0,0.1);
    align-items: center;
    text-align: center;
}
.cardFooter span:last-child{
    border-right: none;
}
.cardFooter span input {
    border: none;
    border-bottom: 2px solid rgba(0,0,0,0.1);
    width: 100%;
    display: block;
}

.emptySummary {
    padding: 25px;
    text-align: center;
    border: 3px dashed rgba(0,0,0,0.1);
    border-radius: 5px;

}

.alert {
    border-radius: 0 !important;
    text-align: center;
}

.cursor-pointer {
    cursor: pointer;
}

.footer {
    margin-top: 150px;
    font-size: 13px;
}

.tabContent {
    padding: 10px 15px;
    border-right: thin solid #dee2e6;
    border-left: thin solid #dee2e6;
    border-bottom: thin solid #dee2e6;
}

.nav-tabs {
    font-weight: bold;
}

th, td {
    font-size: 13px;
}

.bigger {
    font-size: 20px;
    font-weight: 300;
}
@media print {
    .jumbotron {
        display: none;
    }
    .hidden-print {
        display: none;
    }
}

.rbg-text {
    background: linear-gradient(to right, #FD4A5B 0%, #AF1292 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.quantity-selector--icon {
    border: 1px solid #ccc;
    border-radius: 50%;
    margin: 0 10px;
    text-align: center;
    padding: 6px;
    color: darkgray;
    cursor: pointer;
    background: white;
}

.quantity-selector--number {
    border: 1px solid #ccc;
    margin: 0 10px;
    font-size: 16px;
    text-align: center;
    padding: 5px 10px;
    font-weight: bold;
    background: white;
}

.quantity-selector--disabled {
    cursor: not-allowed;
    background-color: #f4f4f4;
    color: #AAAAAA;
}
.navDropdown {
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 50px;
  top: 120%; }

.dropItem {
  padding-left: 15px; }

.invoicesDropdown .btn-primary, .invoicesDropdown.show .btn-primary {
  background: none !important;
  color: rgba(0, 0, 0, 0.5);
  text-transform: none;
  font-weight: normal;
  border: none;
  line-height: 27px; }

.Card_hasLinkAction__35Pdd div, .Card_hasLinkAction__35Pdd p, .Card_hasLinkAction__35Pdd span, .Card_hasLinkAction__35Pdd h3 {
  cursor: pointer; }

.Card_card__2fJLW {
  background: linear-gradient(to right, #FD4A5B 0%, #AF1292 100%);
  border-radius: 5px;
  color: white;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  margin-bottom: 25px;
  transition: box-shadow .2s; }
  .Card_card__2fJLW:hover {
    box-shadow: 0 0 10px black; }
  .Card_card__2fJLW .Card_cardBody__3Cd5K {
    padding: 25px; }
    .Card_card__2fJLW .Card_cardBody__3Cd5K p {
      min-height: 40px; }
    .Card_card__2fJLW .Card_cardBody__3Cd5K h3 {
      max-height: 37px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
  .Card_card__2fJLW .Card_cardFooter__22tjr {
    display: flex; }
  .Card_card__2fJLW .Card_cardFooterItem__EuDo0 {
    border: thin solid rgba(0, 0, 0, 0.1);
    border-bottom: none;
    border-left: none;
    padding: 10px;
    flex-grow: 1;
    text-align: center; }
  .Card_card__2fJLW .Card_cardFooterItem__EuDo0:nth-child(3n) {
    background: linear-gradient(#d1d0d1, white);
    border-right: none;
    border-bottom-right-radius: 3px;
    color: #333; }
  .Card_card__2fJLW .Card_cardFooterItem__EuDo0 a {
    color: inherit; }
  .Card_card__2fJLW .Card_cardBadge__3KxhH {
    background: white;
    color: #1a1a1a;
    position: absolute;
    font-size: 12px;
    padding: 3px 10px;
    border-radius: 2px;
    top: -10px;
    left: 25px;
    font-weight: bold;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4); }

.Card_helpButton__3msuI {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: none;
  border-radius: 10px;
  color: #FFF;
  padding: 4px;
  margin: 0;
  line-height: 11px; }
  .Card_helpButton__3msuI i {
    border: #fff solid 1px;
    border-radius: 20px;
    padding: 5px;
    font-size: 11px; }

.boolean-holder label {
  margin-right: 10px; }
  .boolean-holder label input {
    margin-right: 5px; }

button.overlay-trigger {
  padding: 0;
  margin: 0;
  position: relative;
  border: none; }
  button.overlay-trigger i {
    border-radius: 20px;
    border: #cecece solid 1px;
    color: blue;
    padding: 2px 4px;
    font-size: 11px;
    margin: 0 4px 0 4px;
    line-height: 9px; }

.modal-body .slot-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap; }
  .modal-body .slot-grid .btn {
    width: 100%;
    text-align: center;
    margin: 5px; }

